import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  userActivationAsync,
  userQuestionOfTheDayAsync,
  submitUserAnswerAsync,
} from "./dashboard.async";

const initialState = {
  activatedLoader: false,
  activated: [],
  questionOfTheDayLoader: false,
  questionOfTheDay: [],
  ansLoader: false,
  answers: [],
};

export const dashboardEngSlice = createSlice({
  name: "register",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(userActivationAsync.pending), (state) => {
      state.activatedLoader = true;
    });

    builder.addMatcher(
      isAnyOf(userActivationAsync.fulfilled),
      (state, action) => {
        state.activatedLoader = false;
        state.activated = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(userActivationAsync.rejected),
      (state, action) => {
        state.activatedLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(userQuestionOfTheDayAsync.pending), (state) => {
      state.questionOfTheDayLoader = true;
    });

    builder.addMatcher(
      isAnyOf(userQuestionOfTheDayAsync.fulfilled),
      (state, action) => {
        state.questionOfTheDayLoader = false;
        state.questionOfTheDay = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(userQuestionOfTheDayAsync.rejected),
      (state, action) => {
        state.questionOfTheDayLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(submitUserAnswerAsync.pending), (state) => {
      state.ansLoader = true;
    });

    builder.addMatcher(
      isAnyOf(submitUserAnswerAsync.fulfilled),
      (state, action) => {
        state.ansLoader = false;
        state.answers = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(submitUserAnswerAsync.rejected),
      (state, action) => {
        state.ansLoader = false;
      }
    );
  },
});

export default dashboardEngSlice.reducer;
