import React, { useState } from "react";

import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Box, Stack, Container } from "@mui/system";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import {
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";

function ShareWith({ openShareDialog, setShareOpenDialog, deepLink }) {
  const { getOnlySiteSettingLoader, getOnlySiteSettingData = [] } = useSelector(
    (state) => state?.getOnlySiteSetting
  );
  const Subheading = "";
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Container>
      <Dialog open={openShareDialog} onClose={() => setShareOpenDialog(true)}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <DialogTitle id="dialog-title" sx={{ color: "primary.main" }}>
              {" "}
              Share With Friends...{" "}
            </DialogTitle>
          </Box>

          <Box>
            <IconButton sx={{ color: "primary.main", mr: 2 }} size="small">
              <HighlightOffIcon onClick={() => setShareOpenDialog(false)} />
            </IconButton>
          </Box>
        </Box>

        {deepLink && (
          <Box
            sx={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {deepLink}
          </Box>
        )}

        <DialogContent>
          <Box
            sx={{
              textAlign: "center",
              p: 3,
              display: "flex",
              flexDirection: "row",
              columnGap: 3,
            }}
          >
            {deepLink && (
              <Box
                sx={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#F0F0F0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(deepLink);
                  setIsCopied(true);
                }}
              >
                {isCopied ? (
                  <LibraryAddCheckIcon sx={{ color: "green" }} />
                ) : (
                  <ContentCopyIcon />
                )}
              </Box>
            )}
            <WhatsappShareButton
              url={getOnlySiteSettingData?.socialContent}
              title={Subheading}
            >
              <WhatsappIcon
                size={45}
                className="share-icon"
                logofillcolor="white"
                round={true}
              ></WhatsappIcon>
            </WhatsappShareButton>
            <FacebookShareButton
              url={getOnlySiteSettingData?.socialContent}
              title={Subheading}
            >
              <FacebookIcon
                size={45}
                className="share-icon"
                logofillcolor="white"
                round={true}
              ></FacebookIcon>
            </FacebookShareButton>
            <TwitterShareButton
              url={getOnlySiteSettingData?.socialContent}
              title={Subheading}
            >
              <TwitterIcon
                size={45}
                className="share-icon"
                logofillcolor="white"
                round={true}
              ></TwitterIcon>
            </TwitterShareButton>
            <LinkedinShareButton
              url={getOnlySiteSettingData?.socialContent}
              title={Subheading}
            >
              <LinkedinIcon
                size={45}
                className="share-icon"
                logofillcolor="white"
                round={true}
              ></LinkedinIcon>
            </LinkedinShareButton>
            <EmailShareButton
              url={getOnlySiteSettingData?.socialContent}
              title={Subheading}
            >
              <EmailIcon
                size={45}
                className="share-icon"
                logofillcolor="white"
                round={true}
              ></EmailIcon>
            </EmailShareButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default ShareWith;
