import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const userActivationAsync = createAsyncThunk(
  "user/userActivation",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/userActivation`, payload, toolkit);
  }
);

export const userQuestionOfTheDayAsync = createAsyncThunk(
  "web/userQuestionOfTheDay",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/userQuestionOfTheDay`, [], toolkit);
  }
);

export const submitUserAnswerAsync = createAsyncThunk(
  "user/submitUserAnswer",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/submitUserAnswer`, payload, toolkit);
  }
);
