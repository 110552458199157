import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const userWordOfTheDayAsync = createAsyncThunk(
  "web/userWordOfTheDay",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/userWordOfTheDay`, [], toolkit);
  }
);

export const userTwoDayPreviousWordsAsync = createAsyncThunk(
  "web/userTwoDayPreviousWords",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/userTwoDayPreviousWords`, [], toolkit);
  }
);

export const userWordOfTheDayByIdAsync = createAsyncThunk(
  "web/userWordOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/userWordOfTheDayById/${payload?.id}`,
      [],
      toolkit
    );
  }
);

export const userAllPreviousWordsAsync = createAsyncThunk(
  "web/userAllPreviousWords",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/userAllPreviousWords`, [], toolkit);
  }
);

export const userWordOfTheDayBookmarksAsync = createAsyncThunk(
  "web/userWordOfTheDayBookmarks",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/userWordOfTheDayBookmarks`, [], toolkit);
  }
);
