import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  userWordOfTheDayAsync,
  userTwoDayPreviousWordsAsync,
  userWordOfTheDayByIdAsync,
  userAllPreviousWordsAsync,
  userWordOfTheDayBookmarksAsync,
} from "./wordoftheday.async";

const initialState = {
  wordofDayLoader: false,
  wordofDay: [],
  twodayPrevLoader: false,
  twodayPrev: [],
  wordByIdLoader: false,
  wordById: [],
  allwordsLoader: false,
  allwords: [],
  allbookmarksLoader: false,
  bookmarks: [],
};

export const wordofthedaySlice = createSlice({
  name: "wordoftheday",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(userWordOfTheDayAsync.pending), (state) => {
      state.wordofDayLoader = true;
      state.wordofDay = [];
    });
    builder.addMatcher(
      isAnyOf(userWordOfTheDayAsync.fulfilled),
      (state, action) => {
        state.wordofDayLoader = false;
        state.wordofDay = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userWordOfTheDayAsync.rejected),
      (state, action) => {
        state.wordofDayLoader = false;
        state.wordofDay = [];
      }
    );

    builder.addMatcher(
      isAnyOf(userTwoDayPreviousWordsAsync.pending),
      (state) => {
        state.twodayPrevLoader = true;
        state.wordofDay = [];
      }
    );
    builder.addMatcher(
      isAnyOf(userTwoDayPreviousWordsAsync.fulfilled),
      (state, action) => {
        state.twodayPrevLoader = false;
        state.twodayPrev = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userTwoDayPreviousWordsAsync.rejected),
      (state, action) => {
        state.twodayPrevLoader = false;
        state.wordofDay = [];
      }
    );

    builder.addMatcher(isAnyOf(userWordOfTheDayByIdAsync.pending), (state) => {
      state.wordByIdLoader = true;
      state.wordById = [];
    });
    builder.addMatcher(
      isAnyOf(userWordOfTheDayByIdAsync.fulfilled),
      (state, action) => {
        state.wordByIdLoader = false;
        state.wordById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userWordOfTheDayByIdAsync.rejected),
      (state, action) => {
        state.wordByIdLoader = false;
        state.wordById = [];
      }
    );

    builder.addMatcher(isAnyOf(userAllPreviousWordsAsync.pending), (state) => {
      state.allwordsLoader = true;
      state.allwords = [];
    });
    builder.addMatcher(
      isAnyOf(userAllPreviousWordsAsync.fulfilled),
      (state, action) => {
        state.allwordsLoader = false;
        state.allwords = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userAllPreviousWordsAsync.rejected),
      (state, action) => {
        state.allwordsLoader = false;
        state.allwords = [];
      }
    );

    builder.addMatcher(
      isAnyOf(userWordOfTheDayBookmarksAsync.pending),
      (state) => {
        state.allbookmarksLoader = true;
        state.bookmarks = [];
      }
    );
    builder.addMatcher(
      isAnyOf(userWordOfTheDayBookmarksAsync.fulfilled),
      (state, action) => {
        state.allbookmarksLoader = false;
        state.bookmarks = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userWordOfTheDayBookmarksAsync.rejected),
      (state, action) => {
        state.allbookmarksLoader = false;
        state.bookmarks = [];
      }
    );
  },
});

export default wordofthedaySlice.reducer;
