import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const userAllVocabulariesAsync = createAsyncThunk(
  "web/userAllVocabularies",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/userAllVocabularies`, [], toolkit);
  }
);

export const userVocabularyByWordAsync = createAsyncThunk(
  "user/userVocabularyByWord",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/userVocabularyByWord?startWord=${payload.startWord || ""}&search=${
        payload.search || ""
      }&word=${payload.word || ""}`,
      payload,
      toolkit
    );
  }
);
