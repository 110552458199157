import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  userAllVocabulariesAsync,
  userVocabularyByWordAsync,
} from "./vocabulary.async";

const initialState = {
  allVocabLoader: false,
  allVocab: [],
  vocabLoader: false,
  vocabData: [],
};

export const vocabularySlice = createSlice({
  name: "vocabulary",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(userAllVocabulariesAsync.pending), (state) => {
      state.allVocabLoader = true;
      state.allVocab = [];
    });
    builder.addMatcher(
      isAnyOf(userAllVocabulariesAsync.fulfilled),
      (state, action) => {
        state.allVocabLoader = false;
        state.allVocab = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userAllVocabulariesAsync.rejected),
      (state, action) => {
        state.allVocabLoader = false;
        state.allVocab = [];
      }
    );

    builder.addMatcher(isAnyOf(userVocabularyByWordAsync.pending), (state) => {
      state.vocabLoader = true;
      state.vocabData = [];
    });
    builder.addMatcher(
      isAnyOf(userVocabularyByWordAsync.fulfilled),
      (state, action) => {
        state.vocabLoader = false;
        state.vocabData = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userVocabularyByWordAsync.rejected),
      (state, action) => {
        state.vocabLoader = false;
        state.vocabData = [];
      }
    );
  },
});

export default vocabularySlice.reducer;
